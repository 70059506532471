<template>
  <div>
    <h1>Alle <template v-if="archived">oud-</template>leden</h1>

    <template v-if="!groupId">
      <AdminButton :isAdmin="isAdmin" />

      <UserFilter @set-gender="$value => { gender = $value }"
                  @set-town="$value => { town = $value }"
                  @set-role="$value => { role = $value }"
                  @set-query="$value => { query = $value }"
                  @generate-export="generateExport"
                  @reset-export="exportUrl = null"
                  :exportUrl="exportUrl" />
    </template>

    <UserList :users="users" />

    <p class="pagination" v-if="showNextPage">
      <a @click="page += 1">Laad meer leden</a>
    </p>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from 'villus';

import UserFilter from '../components/UserFilter.vue';
import UserList from '../components/UserList.vue';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  components: { UserFilter, UserList, AdminButton },

  props: {
    isAdmin: Boolean,
    me: Object,
    groupId: String,
  },

  setup(props) {
    const route = useRoute();
    const archived = computed(() => route.path.endsWith('archive'));

    const variables = reactive({
      query: '',
      gender: '',
      role: '',
      town: '',
      page: 0,
      groupId: null,
      archived,
    });

    if (props.groupId) { variables.groupId = Number(props.groupId); }

    const exportUrl = ref(null);
    const showNextPage = ref(true);
    const users = ref([]);

    const FilteredUsers = `
      query FilteredUsers($query: String, $page: Int, $gender: String, $role: String, $town: String, $groupId: Int, $archived: Boolean) {
        filteredUsers(
          query: $query,
          page: $page,
          gender: $gender,
          role: $role,
          town: $town,
          groupId: $groupId,
          archived: $archived
        ) {
          id
          firstName
          lastName
          email
          phonenumber
          town
          groups {
            name
          }
        }
      }`;

    const { data: usersResult } = useQuery({
      query: FilteredUsers,
      variables,
    });

    const GenerateExport = `
      query UserExport($query: String, $gender: String, $role: String, $town: String, $groupId: Int, $archived: Boolean) {
        userExport(
          query: $query,
          gender: $gender,
          role: $role,
          town: $town,
          groupId: $groupId,
          archived: $archived
        ) {
          url
        }
    }`;

    const { data: exportResult, execute: generateExport, unwatchVariables } = useQuery({
      query: GenerateExport,
      fetchOnMount: false,
      variables,
    });

    unwatchVariables();

    const resetUsers = () => {
      variables.page = 0;
      users.value = [];
    };

    watch(archived, () => resetUsers());

    watch(() => variables.query, resetUsers);
    watch(() => variables.gender, resetUsers);
    watch(() => variables.role, resetUsers);
    watch(() => variables.town, resetUsers);

    watch(usersResult, ({ filteredUsers }) => {
      users.value = [].concat(users.value, filteredUsers);

      if (filteredUsers.length === 0) {
        showNextPage.value = false;
      }
    });

    watch(exportResult, ({ userExport }) => {
      exportUrl.value = userExport.url;
    });

    return {
      ...toRefs(variables),
      AdminButton,
      generateExport,
      users,
      showNextPage,
      exportUrl,
    };
  },
});
</script>

<style lang="scss">
.pagination {
  a {
    cursor: pointer;
    display: block;
    padding: 10px;
    text-align: center;
    text-decoration: underline;
  }
}
</style>
