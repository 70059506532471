
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({

  props: {
    users: Array,
  },

  setup() {
    const router = useRouter();
    const userRoute = (user: User) => {
      const userPath = `/users/${user.id}`;
      const groupPath = user.groups.id ? `/groups/${user.groups.id}` : '';

      return `${groupPath}${userPath}`;
    };

    function routeToUser(param: string) {
      router.push({ name: 'UserDetails', params: { id: param } });
    }

    return {
      userRoute,
      routeToUser,
    };
  },
});
