<template>
  <div>
    <table v-if="users">
      <tr @click="routeToUser(user.id)" v-for="user in users" :key="user.id" tag="tr">
        <td class="name">
          {{ user.lastName }}<template v-if="user.firstName">, {{ user.firstName }}</template>
        </td>

        <td class="town">
          {{ user.town }}
        </td>

        <td class="phonenumber">
          {{ user.phonenumber }}
        </td>

        <td class="email">
          {{ user.email }}
        </td>

        <td v-if="user.groups.name">
          {{ user.groups.name }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({

  props: {
    users: Array,
  },

  setup() {
    const router = useRouter();
    const userRoute = (user: User) => {
      const userPath = `/users/${user.id}`;
      const groupPath = user.groups.id ? `/groups/${user.groups.id}` : '';

      return `${groupPath}${userPath}`;
    };

    function routeToUser(param: string) {
      router.push({ name: 'UserDetails', params: { id: param } });
    }

    return {
      userRoute,
      routeToUser,
    };
  },
});
</script>

<style scoped lang="scss">
table {
  border-collapse:collapse;
}

td {
  padding-right: 30px;

  &:last-child { padding-right: 0; }
}

tr:hover {
  background: #002244;
}

tr:hover a {
  color:white;
}

tr:hover {
  color:white;
  cursor:pointer;
}

</style>
