<template>
  <form class="search" autocomplete="off">
    <input type="search"
           v-model="query"
           autocomplete="off"
           placeholder="Zoek op naam of e-mailadres"
           name="searchField" />

    <template v-if="filtersResult">
      <select v-model="gender">
        <option value="">Man / vrouw</option>
        <option
          :value="gender"
          v-for="gender in filtersResult.userFilters.genders"
          :key="gender">{{ gender }}</option>
      </select>

      <select v-model="town">
        <option value="">Alle woonplaatsen</option>
        <option
          :value="town"
          v-for="town in filtersResult.userFilters.towns"
          :key="town">{{ town }}</option>
      </select>

      <select v-model="role">
        <option value="">Alle leden</option>
        <option
          :value="role"
          v-for="role in filtersResult.userFilters.roles"
          :key="role">{{ role }}</option>
      </select>
    </template>

    <a v-if="exportUrl" :href="exportUrl" @click="resetExport">Download</a>
    <a v-else @click="generateExport">Exporteer</a>
  </form>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import { useQuery } from 'villus';

export default defineComponent({
  props: {
    exportUrl: String,
  },

  setup(_props, { emit }) {
    const query = ref('');
    const gender = ref('');
    const role = ref('');
    const town = ref('');

    const generateExport = () => emit('generate-export');
    const resetExport = () => emit('reset-export');

    let queryTimeout = null;
    const setQuery = (newValue) => emit('set-query', newValue);

    const Filters = `query {
        userFilters {
          genders
          towns
          roles
        }
      }`;

    const { data: filtersResult } = useQuery(Filters);

    watch(town, (newValue) => emit('set-town', newValue));
    watch(gender, (newValue) => emit('set-gender', newValue));
    watch(role, (newValue) => emit('set-role', newValue));

    watch(query, (newValue) => {
      clearTimeout(queryTimeout);

      if (newValue.length >= 3) {
        queryTimeout = setTimeout((() => setQuery(newValue)), 500);
      } else {
        emit('set-query', '');
      }
    });

    return {
      filtersResult,
      generateExport,
      resetExport,
      query,
      gender,
      role,
      town,
    };
  },
});
</script>

<style lang="scss" scoped>
.search {
  background: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;

  select {
    margin-left: 5px;
    min-width: auto;
    width: 150px;
  }
}

a {
  cursor: pointer;
  margin-left: 10px;
  text-decoration: underline;
}
</style>
